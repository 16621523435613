.eventSelect {
  // background-color: lightblue;
  user-select: none;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  min-width: 148px;
  padding: 9px 10px;
  cursor: pointer;

  position: relative;

  .eventSelectTitleBox {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      height: 20px;
    }
    .title {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;
      justify-content: space-between;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      min-width: 120px;
    }
  }
}

.eventItems {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  div {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;

    transition: 0.3s all;
    padding: 7px 0 8px 10px;

    &:hover {
      background: #173593;
      border-radius: 4px;
      color: #fff;
    }
  }

  .activeItem {
    background: #173593;
    border-radius: 4px;
    color: #fff;
  }
}
