.wrapper {
  transform: translate(-45px, 45px);
  > .img {
    width: 35px;
    height: 42px;
  }
  > .badgeCount {
    position: relative;
    bottom: 42px;
    left: 13px;
    color: #fff;
  }
}
