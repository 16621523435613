.container {
  margin-top: 40px;
  position: relative;
}

.container:last-child {
  margin-bottom: 40px;
}

.container > .cont {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  align-content: flex-start;

  background-color: white;
  border: 1px solid var(--that_grey);
  border-radius: 8px;

  //
  //
  // loader
  .loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    min-height: 100px;

    > svg {
      animation: spin 2s linear infinite;

      width: 50px;
      height: 50px;

      > path {
        color: grey;
      }
    }

    > span {
      color: grey;
      margin-top: 10px;
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  //
  //
  // gradient

  /* &:before {
		content: '';
		border-radius: inherit;
		transition: 300ms;

		pointer-events: none;
		position: absolute;
		padding: 1px;
		inset: 0;

		background: var(--gradient);

		mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
		-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
		mask-composite: exclude;
		-webkit-mask-composite: xor;
	} */

  //
  //
  // children

  > hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--that_grey);

    margin: 30px 0;
  }

  //
  //
  //

  @media screen and (min-width: 1728px) {
    width: calc(100% - 100px);
    padding: 49px;
  }

  @media screen and (max-width: 1727px) {
    width: calc(100% - 50px);
    padding: 25px;
  }
}

.static {
  @media screen and (min-width: 1728px) {
    width: 1000px;
  }

  @media screen and (max-width: 1727px) and (min-width: 768px) {
    width: 678px;
  }

  @media screen and (max-width: 767px) {
    width: 360px;
  }
}

.flex {
  display: flex;

  @media screen and (min-width: 868px) {
    width: calc(100% - 100px);
    max-width: 1800px;
  }

  @media screen and (max-width: 867px) and (min-width: 768px) {
    width: calc(100%);
  }

  @media screen and (max-width: 767px) and (min-width: 460px) {
    width: 100%; // width: calc(100% - 100px);
    padding: 10px; //was 25px before
  }

  @media screen and (max-width: 459px) {
    width: calc(100%);
  }
}

.borderless > .cont {
  border: none;
  background-color: transparent;

  &:before {
    display: none;
  }
}

//
//
//

.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: flex-start;

  background-color: white;
  border: 1px solid var(--light_grey);
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;

  margin-top: 40px;
  &:last-child {
    margin-bottom: 40px;
  }

  //
  //
  // title
  &:has(.h2title) {
    @import '../../assets/styles/colors.scss';

    > .h2title {
      margin: -25px;
      margin-bottom: 20px;
      padding: 16px;
      padding-left: 25px;

      border-radius: 8px 8px 0 0;

      @media screen and (min-width: 1728px) {
        margin: -50px;
        margin-bottom: 20px;
        padding: 16px;
        padding-left: 50px;
      }
    }
  }

  //
  //
  // loader
  > .loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    min-height: 100px;

    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    > svg {
      animation: spin 2s linear infinite;

      width: 50px;
      height: 50px;

      > path {
        color: grey;
      }
    }

    > span {
      color: grey;
      margin-top: 10px;
    }
  }

  //
  //
  // hr line
  > hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--that_grey);

    margin: 30px 0;
  }

  //
  //
  // default media
  @media screen and (min-width: 1728px) {
    width: calc(100% - 100px);
    padding: 49px;
  }

  @media screen and (max-width: 1727px) {
    width: calc(100% - 50px);
    padding: 25px;
  }

  @media screen and (max-width: 360px) {
    border-left: none;
    border-right: none;
    border-radius: 0;

    &:has(.h2title) > .h2title {
      border-radius: 0;
    }
  }

  //
  //
  // container types

  &.static {
    @media screen and (min-width: 1728px) {
      width: 1010px;
    }

    @media screen and (max-width: 1727px) and (min-width: 768px) {
      width: 678px;
    }

    @media screen and (max-width: 767px) {
      width: 360px;
    }
    @media screen and (max-width: 360px) {
      width: 340px;
    }
  }

  &.flex {
    @media screen and (min-width: 868px) {
      width: calc(100% - 100px);
    }

    @media screen and (max-width: 867px) and (min-width: 768px) {
      width: calc(100% - 10px);
    }

    @media screen and (max-width: 767px) and (min-width: 460px) {
      width: calc(100% - 50px);
    }

    @media screen and (max-width: 459px) and (min-width: 361px) {
      width: calc(100% - 20px);
      padding: 10px;
    }

    @media screen and (max-width: 360px) {
      width: calc(100% - 20px);
      padding: 10px;
    }
  }

  &.borderless {
    border: none;
    background-color: transparent;

    &:before {
      display: none;
    }
  }
}
