@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap');

.inviteMembersModal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background: rgba(51, 51, 51, 0.4);

  .closeBtn {
    text-align: right;

    svg {
      cursor: pointer;
    }
  }

  .modal {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    border-radius: 8px;
    width: 600px;

    background-color: #fff;

    padding: 30px;

    display: flex;
    flex-direction: column;

    gap: 15px;

    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      align-self: center;
    }

    .inviteMembersModalWrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 50px;

      .inviteInputBlock {
        input {
          // width: 100%;
          width: 96%;
          border: 1px solid #e6e6e6;
          border-radius: 8px;
          padding: 9px 0 9px 20px;
          color: #363636;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
        }
      }
      .groupMembers {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .inviteItemInfoWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #b1b1b1;
          border-radius: 8px;
          padding: 15px 20px;

          .itemInfo {
            display: flex;
            align-items: center;
            gap: 20px;

            .itemPhoto {
              width: 70px;

              img {
                width: 100%;
                height: 70px;
                border-radius: 50%;
              }
            }

            .inviteItemName {
              display: flex;
              flex-direction: column;
              gap: 2px;

              h3 {
                color: #363636;
                font-weight: 600;
                font-size: 20px;
                line-height: 27px;
              }

              span {
                color: #363636;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    .membersWrapper {
      display: flex;
      flex-direction: column;
      //   gap: 5px;
      border: 1px solid #b1b1b1;
      border-radius: 8px;
      padding: 15px 0;
      span {
        color: #b1b1b1;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding: 5px 20px;
        cursor: pointer;
        transition: 0.3s all;

        &:hover {
          background: #e6eff9;
        }
      }
    }

    .inviteModalButtonBlock {
      align-self: flex-end;
    }
  }
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 70vh; // fix -it

  > svg {
    animation: spin 2s linear infinite;

    width: 50px;
    height: 50px;

    > path {
      color: grey;
    }
  }

  > span {
    color: grey;
    margin-top: 10px;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
