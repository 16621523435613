.dropdownContainer {
  width: 100%;
  .dropdownBox {
    width: 100%;
    @media screen and (max-width: 1727px) and (min-width: 768px) {
      width: 100%;
    }
    @media screen and (max-width: 767px) and (min-width: 200px) {
      width: 100%;
    }
    &.spicialties {
      @media screen and (max-width: 767px) and (min-width: 200px) {
        margin-bottom: 5px;
      }
    }
  }
}
