.addServBox {
  width: 100%;
  .addBox {
    display: block;
    color: var(--link_color);
    background-color: transparent;
    float: right;
    border: none;
    margin-top: 10px;
    font-size: 16px;
    &.consigner {
      color: var(--directory_text_purple);
    }
    &.seller {
      color: var(--directory_text_green);
    }
  }
}
