.aboutmeContainer {
  width: 100%;
  margin-top: 20px;
  @media screen and (max-width: 767px) and (min-width: 200px) {
    width: 100%;
  }
  h2,
  span {
    font-size: 20px;
    font-weight: 600;
    @media screen and (max-width: 767px) and (min-width: 200px) {
      font-size: 18px;
    }
  }
  input {
    font-size: 16px;
  }
  > .inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    @media screen and (max-width: 767px) and (min-width: 200px) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0;
    }
    > .fieldNameDiv {
      display: flex;
      flex-direction: row;
      font-size: 24px;
      font-weight: 600;
      width: calc(33% + 15px);
      margin-bottom: 3%;
      margin-top: 3%;
      @media screen and (max-width: 1727px) and (min-width: 768px) {
        width: calc(44% + 8px);
      }
      @media screen and (max-width: 767px) and (min-width: 200px) {
        width: 100%;
      }
    }
    > .inputDiv {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      box-sizing: border-box;
      justify-content: space-between;
      width: 100%;
      .padlock {
        display: flex;
        border: 1px solid var(--that_grey);
        border-radius: 8px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        height: 40px;
        background-color: var(--light_blue_boxes);
        justify-content: center;
        text-align: center;
        align-items: center;
        > span {
          font-weight: 300;
          font-size: 16px;
        }
      }
      .usernameTip {
        width: 100%;
        font-size: 12px;
        color: #ff4a1a;
      }
      > .bio {
        flex-grow: 1;
        height: 230px;
        margin-top: 20px;
        border-radius: 8px;
        border: 1px solid var(--that_grey);
        padding: 10px;
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
        @media screen and (max-width: 767px) and (min-width: 200px) {
          margin-top: 0;
        }
      }
    }
    .countryDropdown,
    .regionDropdown {
      padding: 5px;
      height: 40px;
      border-radius: 8px;
      border: 1px solid var(--that_grey);
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      width: calc(30% + 10px);
      margin-bottom: 10px;
      &:not(.regionDropdown) {
        width: 100%;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 1727px) and (min-width: 768px) {
        width: calc(33% + 5px);
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

.saveBtn {
  margin-top: 20px;
  margin-left: auto;
}
