.input {
  padding: 5px;
  height: 30px;
  border-radius: 8px;
  border: 1px solid var(--that_grey);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  &.name {
    width: calc(46% + 10px);
    @media screen and (max-width: 1727px) and (min-width: 768px) {
      width: calc(44% + 10px);
    }
    @media screen and (max-width: 767px) and (min-width: 200px) {
      margin-top: 10px;
      width: 100%;
    }
  }
  &.username {
    flex-grow: 1;
    border-right: none;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    @media screen and (max-width: 767px) and (min-width: 200px) {
      margin-bottom: 15px;
      width: 100px;
    }
  }
  &.usernameDisabled {
    flex-grow: 1;
    opacity: 0.5;
    //pointer-events:none;
    border-right: none;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    @media screen and (max-width: 767px) and (min-width: 200px) {
      margin-bottom: 15px;
      width: 100px;
    }
  }
  //check width
  &.country {
    width: 40%;
    margin-right: 10px;
    @media screen and (max-width: 767px) and (min-width: 374px) {
      margin-right: 0px;
      margin-bottom: 10px;
      width: 100%;
    }
    @media screen and (max-width: 374px) and (min-width: 200px) {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }

  &.city {
    width: 40%;
    margin-right: 10px;
    @media screen and (max-width: 767px) and (min-width: 200px) {
      margin-right: 0px;
      width: 100%;
    }
    @media screen and (max-width: 374px) and (min-width: 200px) {
      width: 100%;
    }
  }
  &.state {
    width: calc(20% + 10px);
    margin-right: 10px;
    @media screen and (max-width: 767px) and (min-width: 375px) {
      margin-right: 0px;
      margin-top: 10px;
      width: calc(50% - 15px);
    }
    @media screen and (max-width: 374px) and (min-width: 200px) {
      width: 100%;
      margin-top: 10px;
      margin-right: 0px;
    }
  }
  &.zip {
    width: calc(20% + 10px);
    @media screen and (max-width: 1727px) and (min-width: 768px) {
      width: calc(42% + 5px);
    }
    @media screen and (max-width: 767px) and (min-width: 374px) {
      margin: 10px 0 0 0;
      width: calc(50% - 15px);
    }
    @media screen and (max-width: 374px) and (min-width: 200px) {
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
    }
  }
  &.bio {
    flex-grow: 1;
    height: 230px;
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    @media screen and (max-width: 767px) and (min-width: 200px) {
      margin-top: 0;
    }
  }
  &.otherType {
    border: none;
    font-family: 'Open Sans', sans-serif;
    margin-left: 10px;
    font-size: 16px;
    margin-top: -10px;
    background: transparent;
    width: 50%;
  }
  &.site {
    border: none;
    width: 170px;
    padding-left: 10px;
    @media screen and (max-width: 1727px) and (min-width: 768px) {
      width: 100px;
    }
    @media screen and (max-width: 767px) and (min-width: 375px) {
      width: 90px;
    }
    @media screen and (max-width: 374px) {
      width: 250px;
    }

    &.activeconsigner {
      background-color: var(--light_purple);
      color: var(--that_purple);
    }
    &.activeseller {
      background-color: var(--light_green);
      color: var(--that_green);
    }
    &.activeassistant {
      background-color: var(--light_blue);
      color: var(--that_blue);
    }
    /* &.active {
			background-color: transparent;
		}; */
  }
  &.city,
  &.zip {
    width: calc(30% + 5px);
    margin: 0;
    height: 28px;
    margin-bottom: 10px;
    @media screen and (max-width: 1727px) and (min-width: 768px) {
      width: calc(27% + 5px);
    }
    @media screen and (max-width: 767px) {
      display: flex;
      width: 100%;
    }
  }
  &.price {
    margin: 0;
    margin-top: -5px;
    padding: 0;
    border: none;
    border-radius: 0px;
  }
}
