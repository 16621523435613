@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

.form {
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #e6e6e6;
  border-radius: 8px !important;
  background-color: #fff;
  height: 94px;
  // padding: 15px;
  padding: 15px 0;

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  textarea {
    border: none;
    outline: none;
    flex: 1;
    resize: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    width: calc(90% - 50px);

    height: 100%;
    resize: none;
    overflow: hidden;
    overflow-y: auto;

    &::placeholder {
      color: #b1b1b1;
    }

    @media (max-width: 768px) {
      width: 90%;
    }
  }

  .reactQuill_textArea {
    border: none;
    outline: none;
    flex: 1;
    resize: none;
    font-weight: 400;
    font-size: 16px;

    line-height: 22px;
    width: calc(90% - 50px);

    height: 100%;
    resize: none;
    overflow: hidden;
    overflow-y: auto;
    flex: 1;
    height: 100%;

    div {
      font-size: inherit;
    }

    &::placeholder {
      color: #b1b1b1;
    }

    @media (max-width: 768px) {
      width: 90%;
    }
  }

  // button {
  // border: none;
  // background: #173593;
  // position: absolute;
  // bottom: 15px;
  // right: 15px;
  // padding: 10px 15px;
  // border-radius: 8px;
  // color: #fff;
  // display: flex;
  // align-items: center;
  // font-size: 16px;
  // line-height: 22px;
  // cursor: pointer;

  //   @media (max-width: 768px) {
  //     padding: 5px;
  //   }

  //   span {
  //     @media (max-width: 768px) {
  //       display: none;
  //     }
  //   }

  //   img {
  //     margin-right: 10px;

  //     @media (max-width: 768px) {
  //       margin: 0;
  //     }
  //   }
  // }

  .titleFromListings {
    cursor: pointer;
    font-weight: bold;
  }

  .sendForm__send__block {
    position: absolute;
    right: 15px;
    display: flex;
    gap: 8px;

    .attachments {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    button {
      border: none;
      background: #173593;

      padding: 10px 15px;
      border-radius: 8px;
      color: #fff;
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 22px;
      cursor: pointer;
    }
  }
}

.backgroundAttachment {
  cursor: pointer;
  background: #e2efff;
  border-radius: 8px;

  svg {
    padding: 10px 11px;
  }
}

.emoji {
  position: absolute;
  top: -400px;
  right: 0;
}
