.reportMessage__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background: rgba(51, 51, 51, 0.4);

  .modal {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    border-radius: 8px;
    width: 600px;

    background-color: #fff;
    padding: 30px 34px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 15px;

    .modal__content {
      font-family: inherit;
      font-size: 16px;
      font-style: italic;
    }

    h1 {
      font-family: inherit;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
    }

    .modal__btns {
      display: flex;
      align-items: center;
      gap: 21px;
    }
  }
}
