.section {
  width: 100%;
  margin: 30px 0 30px 0;
}
.heading {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 600;
}
.saveBtn {
  margin-top: 20px;
  margin-left: auto;
  &.consigner {
    background-color: var(--directory_text_purple);
  }
  &.seller {
    background-color: var(--directory_text_green);
  }
}
