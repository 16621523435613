.wrapper {
  width: 100%;
  height: calc(100vh - 60px);

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  > .bodya {
    margin: 20px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > svg {
      width: 100px;
      height: 100px;

      margin-bottom: 24px;
    }

    > .textBox {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      background: white;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 15px;

      padding: 40px;

      > .title {
        font-size: 32px;
        font-weight: 600;
        user-select: none;

        > span {
          font-size: 32px;
          font-weight: 700;
        }
      }

      > .text {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 32px 0;

        font-size: 16px;
        font-weight: 400;

        > span > a > span {
          font-weight: 600;
        }

        > :first-child {
          display: block;
        }
      }

      > .buttons {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        gap: 10px;

        > .loginBtn,
        > .loginBtn2 {
          border-radius: 8px;
          width: 260px;
          height: 40px;
        }

        > .loginBtn2 {
          background-color: darkred;
        }
      }
    }
  }

  > .footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 16px;
    color: var(--text_color);
    user-select: none;

    font-weight: 400;
    a {
      font-weight: 600;
    }
  }
}
