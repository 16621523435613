.checkmarkBoxContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, [col] 1fr);
  grid-template-rows: repeat(2, [row] 45px);
  grid-gap: 10px;
  @media screen and (max-width: 1727px) and (min-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) and (min-width: 200px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  &.pref {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 1727px) and (min-width: 768px) {
      width: 100%;
    }
    @media screen and (max-width: 767px) and (min-width: 200px) {
      width: 100%;
    }
  }
}
