.topText {
  @media screen and (max-width: 767px) and (min-width: 200px) {
    text-align: center;
  }
}

.container {
  width: 100%;

  .row {
    display: flex;
    flex-direction: row;
    gap: 28px;
    flex-flow: wrap;
    background-color: transparent;

    @media screen and (max-width: 767px) and (min-width: 200px) {
      justify-content: center;
    }

    > .loadingDiv {
      width: 100%;
      margin: 10px;
      text-align: left;
      font-size: 20px;
    }
  }

  .wrapper {
    border: solid 1px #e9eff0;
    border-radius: 8px;
    background-color: white;
    width: 360px;

    @media screen and (max-width: 380px) {
      width: 320px;
    }

    .block {
      width: 100%;
      border-radius: 8px;

      .image {
        display: flex;
        padding: 20px;
        justify-content: center;
        align-items: center;
        height: 300px;

        > img {
          object-fit: cover;
          max-width: 320px;
          max-height: 300px;
          border-radius: 8px;
          border: none;
        }

        > div {
          display: flex;
          flex-direction: column;
          gap: 10px;
          color: var(--that_grey);

          > svg {
            width: 50px;
            height: 50px;
            color: var(--that_grey);
          }

          > span {
            font-size: 24px;
          }
        }

        > div {
          display: flex;
          width: 100%;
          height: 300px;

          justify-content: center;
          align-items: center;

          border: solid 1px (var(--text_color));
          border-radius: 8px;

          > svg {
            width: 100px;
            height: 100px;
          }
        }
      }
    }

    > .line {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .title {
        p {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;

          //width: 320px;

          margin-right: 20px;
          margin-left: 20px;
          margin-top: 0px;
          margin-bottom: 0px;

          font-size: 20px;
          font-weight: 600;
        }
      }

      > .price {
        display: flex;
        width: 100%;
        padding: 20px;
        gap: 10px;
        align-items: center;

        h3 {
          color: #009364;
          font-size: 20px;
          font-weight: 600;
          margin: 0;
        }
      }
    }

    .moreMpsIcon {
      width: 22px;
      height: 22px;
    }

    .mpsDiv {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 10px;
      width: 100%;
      border-bottom: 1px solid #e9eff0;
      border-top: 1px solid #e9eff0;

      .mpsPool {
        display: flex;
        flex-direction: row;
        padding: 10px;
        gap: 10px;

        .mpImg {
          display: flex;
          cursor: pointer;

          img {
            width: 22px;
            height: 22px;
          }
        }

        > p {
          margin: 0;
        }
      }
    }

    .lastLine {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      //text-align: center;
      margin: 20px;

      @media screen and (max-width: 380px) {
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        text-align: center;
      }

      .left {
        display: flex;
        gap: 10px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        > h3 {
          font-size: 16px;
          font-weight: 600;
          margin-right: 10px;
          width: 160px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          @media screen and (max-width: 380px) {
            width: auto;
          }
        }

        @media screen and (max-width: 380px) {
          width: auto;
          gap: 10px;
          text-align: left;
        }
      }

      .button {
        // width: 125px; ??
        width: fit-content;
        padding: 8px 15px;

        height: 50px;
        margin: 10px 0;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;

        span {
          font-family: inherit;
        }
      }

      .icon {
        width: 30px;
        height: 30px;
      }
    }
  }
}

//
//
