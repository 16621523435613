.addGoalContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;

  background-color: white;
  @media screen and (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    width: 280px;
  }
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--that_blue);
    background-color: var(--light_blue);

    margin: -20px -20px 0 -20px;
    padding: 10px 0 10px 25px;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    font-size: 20px;

    width: calc(102% + 3px);
    @media screen and (max-width: 767px) {
      width: calc(103% + 7px);
    }
    > svg {
      margin-right: 25px;
      cursor: pointer;
    }
  }

  > .inputsDiv {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
    > .dueDateDropdown {
      display: flex;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
    > .inputs {
      display: flex;
      flex-direction: row;
      width: 100%;
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
      > div {
        display: flex;
        flex-direction: column;
        width: 50%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
    > .dueDatePicker {
      display: flex;
      flex-direction: column;
      gap: unset;
    }
    > div {
      display: flex;
      flex-direction: row;
      gap: 20px;
      input {
        flex: 2;

        padding: 7px;
        border: 1px solid var(--that_grey);
        border-radius: 8px;

        font-size: 16px;
      }
    }
    > .notes {
      border-radius: 8px;
      border: 1px solid var(--that_grey);
      width: calc(100% - 10px);
      padding: 5px;
      &.active {
        border: 2px solid var(--that_blue);
        border-radius: 4px;
      }
      textarea {
        width: 100%;
        border: none;
      }
    }
    .msg {
      font-size: 14px;
      color: var(--that_red);
      padding-left: 5px;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: right;
  }
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: 280px;
  }
  .dueDateBtnDiv {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
