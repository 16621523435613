.wrapper {
  width: 100%;
  height: 100%;

  > hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--that_grey);

    // margin: 30px 0;
  }

  > .wrapper2 > .item {
    user-select: none;

    height: 38px;
    margin-top: 12px;
    border: 2px solid transparent;
    border-radius: 2px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    > a {
      width: 100%;
      height: 100%;

      > .line {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;

        text-decoration: none;
        color: var(--text_color);

        > svg,
        > span {
          margin-left: 18px;
          font-size: 16px;
        }
      }
    }

    &.active {
      border-radius: 8px;
      // border-color: var(--that_orange);

      background-color: #e6eff9;

      svg:not([nopaint]) > path {
        fill: var(--that_blue);
      }

      span {
        color: var(--that_blue);
        font-weight: 600;
      }
    }

    &.child {
      margin-left: 42px;
      margin-top: 4px;
      border: none;

      > span {
        opacity: 0.7;
        font-weight: 400;
        color: var(--text_color);
        transition: 100ms;
      }

      &.active > span {
        opacity: 1;
        font-weight: 600;
      }
    }
  }

  > .wrapper2 > .item {
    &:not(.active) ~ .child {
      display: none;
    }
    &.active ~ .child {
      display: flex;
    }
  }
}

// regular
//
// collapsed

.collapsed > .wrapper2 > .item {
  width: 38px;

  margin: auto;
  height: 38px;
  margin-top: 12px;

  > a > .line {
    > svg {
      margin: auto;
    }

    > span {
      opacity: 0;
      width: 0;
      margin: 0;
    }
  }

  &.active {
    border-radius: 8px;
  }

  &.child {
    border: 0;
    height: 0;
    margin: 0;
    opacity: 0;
    display: block;
  }
}

// collapsed
//
// anime

.wrapper > .wrapper2 > .item {
  transition: 300ms;
}
