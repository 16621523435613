.red {
  color: var(--that_red);
  background-color: var(--light_red);
}

.orange {
  color: var(--that_orange);
  background-color: var(--light_orange);
}

.yellow {
  color: var(--that_yellow);
  background-color: var(--light_yellow);
}

.green {
  color: var(--that_green);
  background-color: var(--light_green);
}

.blue {
  color: var(--that_blue);
  background-color: var(--light_blue);
}

.pink {
  color: var(--that_pink);
  background-color: var(--light_pink);
}

.purple {
  color: var(--that_purple);
  background-color: var(--light_purple);
}

.grey {
  color: var(--text_color);
  background-color: var(--clear_white);
}
