.noteContainer {
  width: 895px;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 1727px) and (min-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) and (min-width: 200px) {
    width: 100%;
  }
  .notesDiv {
    width: 97%;
    > textarea {
      width: 100%;
      font-size: 16px;
      font-family: Open Sans;
      border-radius: 8px;
      border: 1px solid var(--that_grey);
      padding: 10px;
      @media screen and (max-width: 767px) and (min-width: 200px) {
        width: 96%;
      }
    }
  }
}
