.year {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.month {
  margin-bottom: 30px;
}

.month-name {
  color: #3174ad;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.day {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.eventYearly {
  position: relative;
}
.date {
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50px;
  border: none;
  outline: none;
}

.date.in-month:hover {
  cursor: pointer;
  background: #3174ad;
  color: #fff;
}

.today {
  background: #3174ad;
  color: #fff;
}

.date.prev-month,
.date.next-month {
  color: grey;
}

.wrapper {
  margin-bottom: 10px;
  padding: 0 20px;
}

.modalDirectory {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  z-index: 1111111;
  background-color: #fff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 10px;
  height: 300px;
  width: 600px;
}
.header-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  position: fixed !important;
  padding: 10px;
  width: 100%;
  z-index: 999999999;
  top: 5px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.content-container {
  overflow-y: scroll;
  max-height: 400px;
  /* margin-top: 50px; */
}

.modalDirectory p {
  text-transform: capitalize;
  font-size: 14px;
}
.rightSide {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  gap: 15px;
}

.topPart {
  display: flex !important;
  text-align: left;
  gap: 20px;
}
.left {
  width: 100px;
  height: 100px;
}
.left img {
  width: 100px;
}
.left svg {
  transform: translate(10px, -20px);
}
.rightSide {
  padding-bottom: 30px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  width: 3px;
  background: #4f4f4f;
}
