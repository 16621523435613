.mobileMenuWrapper2 {
  width: 100%;

  color: var(--text_color);
  background-color: var(--clear_white);

  display: flex;
  justify-content: center;

  position: fixed;
  z-index: 10000;
  max-height: 100% !important;

  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;

  .mobileMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 360px;

    > .line,
    > .item {
      width: 70px;
    }

    .wrapper {
      min-width: 70px;
    }

    .line {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        min-width: 18px;
        min-height: 18px;
        text-align: center;

        border: 1px solid transparent;
        border-radius: 10px;
        padding: 4px;

        > path {
          fill: var(--text_color);
        }
      }

      span {
        color: var(--text_color);

        font-size: 10px;
        font-weight: 400;
      }
    }

    .active {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        border-color: var(--that_blue);
        border-radius: 8px;

        > path {
          fill: var(--that_blue);
        }
      }
    }
  }
}

.burgerMenuWrapper {
  z-index: 10000;
  background-color: var(--gray_shadow);

  display: flex;
  justify-content: center;
  position: fixed;

  left: 0;
  top: 0;
  right: 0;
  bottom: 60px;

  > .burgerMenu {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;

    color: var(--text_color);
    background-color: var(--clear_white);
    border-radius: 8px 8px 0 0;

    width: 320px;
    padding: 0 20px;

    position: inherit;
    overflow: auto;

    @media screen and (max-height: 600px) {
      top: 20px;
    }

    bottom: 60px;

    //
    //
    //

    > .arm {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      // margin-bottom: 10px;

      > * {
        user-select: none;
        -webkit-user-drag: none;
      }

      height: 20px;

      > svg {
        margin-top: 10px;
      }
    }

    > .body {
      height: 100%;
      width: 100%;

      margin-bottom: 16px;
    }

    .wrapper {
      width: 100%;
    }

    .item {
      width: 100%;
      height: 50px;

      border-top: 1px solid var(--that_grey);

      > a {
        color: var(--text_color);
      }

      &:hover {
        background-color: var(--light_blue);
      }

      &.active {
        background-color: var(--that_blue);

        svg > path {
          fill: var(--clear_white);
        }

        span {
          color: var(--clear_white);
        }
      }

      > a > .line {
        display: flex;
        align-items: center;

        height: 49px;

        padding: 0 20px;
        font-size: 18px;

        > svg {
          width: 22px;
          height: 22px;
          margin-right: 8px;
        }
      }
    }

    > .item:last-child {
      margin-bottom: 30px;
    }
  }
}

.filterButton {
  position: fixed;
  z-index: 1001;

  right: 20px;
  bottom: 80px;

  width: 40px;
  height: 40px;
}

.wrapper {
  display: flex;
  justify-content: center;

  position: fixed;
  z-index: 10000;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 60px !important;
  max-height: 60px !important;

  color: var(--text_color);
  background-color: var(--clear_white);

  transition: 300ms;

  &.hidde {
    transform: translate(0, 60px);
  }

  > .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 360px;

    > .linkWrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 80px;
      height: 100%;

      span,
      > .item > a {
        text-decoration: none;
        color: var(--text_color);
      }

      > .line,
      > .item > a > .line {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        user-select: none;
        position: relative;

        > svg {
          width: 20px;
          height: 20px;

          margin: 4px 0;

          color: var(--text_color);
        }

        > span,
        > svg {
          transition: 200ms;
        }
      }

      > .line.active,
      > .item.active > a > .line {
        > span,
        > svg {
          //   color: var(--that_blue);
          color: var(--text_color);

          font-weight: 500;
        }
      }
    }

    > .centredLogo {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 70px;
      height: 44px;

      > svg {
        height: 100%;
        width: auto;
      }
    }
  }

  > .rainbrow {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    width: 100%;
    height: 1px;

    background: var(--gradient);
  }
}

.inboxNotification {
  position: absolute;
  top: 9%;
  right: 18%;
  background: #e45959;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
