.mainContainer {
  margin-top: 40px;
  padding: 20px;
  gap: 20px;

  .joinEventCard {
    display: flex;
    justify-content: flex-end;
    button {
      @media screen and (max-width: 768px) {
        color: var(--clear_white);
        background: var(--that_blue);
        border: none;
        border-radius: 8px;
        padding: 10px 20px;
        width: auto;
        height: auto;
        min-width: -moz-fit-content;
        min-width: fit-content;
        font-size: 16px;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        transition: 300ms;
      }
    }
  }
  .leftButtonsContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
  }
  .hangoutButton {
    cursor: pointer;
    color: #173593;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    border-radius: 8px;
    border-width: 2px;
    background-color: #e6eff9;
    // border-style: solid;
    white-space: nowrap;
    &::before {
      content: '';
      z-index: 1;
      border-radius: inherit;
      transition: 300ms;
      pointer-events: none;
      position: absolute;
      padding: 2px;
      inset: 0;
      background: linear-gradient(
        90deg,
        rgba(101, 223, 70, 1) 0%,
        rgba(0, 148, 255, 1) 24%,
        rgba(220, 78, 255, 1) 49%,
        rgba(255, 74, 26, 1) 75%,
        rgba(255, 178, 0, 1) 100%
      );
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: xor;
    }
  }
  .joinPartyEventcardMobile {
    color: var(--clear_white);
    background: var(--that_blue);
    border: none;
    border-radius: 8px;
    width: 200px;
    height: 40px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    transition: 300ms;
  }
  .addCalendarEventCard {
    display: flex;
    justify-content: center;
  }
  .linkUnderline {
    text-decoration: underline;
    text-underline-offset: 8px;
  }

  .tabsNav {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .container {
      opacity: 50%;
      max-width: 1800px;
      > h1 {
        font-size: 22px;
        font-weight: 600;
        color: var(--that_blue);

        &:hover {
          cursor: pointer;
        }
      }

      &.active {
        opacity: 100%;
        border-bottom: solid 4px var(--that_blue);
        font-weight: 1000;
      }
    }
  }

  .pinned {
    position: sticky;
    z-index: 1;
    top: 0px;
    height: max-content;
    padding-top: 15px;
    padding-bottom: 10px;
    border: 1px solid;
    border-radius: 8px;
    border-image: linear-gradient(
        90deg,
        #65df46 0%,
        #0094ff 25%,
        #dc4eff 50%,
        #ff4a1a 75%,
        #ffb200 100%
      )
      1;
    @media screen and (max-width: 778px) {
      top: 0;
    }
    .bottom {
      margin-top: 0 !important;
    }
  }
  .pinned:first-child {
    @media screen and (min-width: 778px) {
      top: 70px;
    }
  }
  .cardContainer {
    margin-top: 0;
    border-radius: 8px;
    width: 100% !important;

    &.gradientBorder {
      border: 1px solid;
      border-radius: 8px;
      border-image: linear-gradient(
          90deg,
          #65df46 0%,
          #0094ff 25%,
          #dc4eff 50%,
          #ff4a1a 75%,
          #ffb200 100%
        )
        1;
    }

    @media screen and (max-width: 1400px) and (min-width: 988px) {
      min-width: 800px;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    &:hover {
      border: solid 1px var(--that_blue);
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      .pinnedIcon {
        position: absolute;
        right: 10px;
        top: 10px;
      }

      > .id {
        display: none;
      }

      > svg {
        position: absolute;
        bottom: 25px;
        right: 25px;

        @media screen and (min-width: 1728px) {
          right: 50px;
        }

        /* border: solid 1px var(--that_grey);
                    border-radius: 8px;
                    padding: 0 6px 0 6px; */
      }

      .left {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-wrap: wrap;
        min-width: 250px;
        min-height: 250px;
        max-width: 250px;
        max-height: 250px;
        height: 250px;
        width: 250px;
        gap: 15px;
        align-content: center;
      }
      .leftNormalImage {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-wrap: wrap;
        min-width: 250px;
        min-height: 250px;
        max-width: 250px;
        max-height: 250px;
        height: 250px;
        width: 250px;
        gap: 15px;
        align-content: center;

        img {
          object-fit: cover;
          width: 250px;
          height: 250px;
          border-radius: 8px;
          //margin-right: 25px;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        flex: 3;
        margin-left: 25px;

        .top {
          display: flex;
          flex-direction: row;

          .leftSide {
            display: flex;
            flex-direction: column;
            min-width: 150px;
            gap: 15px;

            > h1 {
              font-size: 20px;
              font-weight: 600;
              margin: 0 15px 15px 0;
            }

            .username {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: 400;
              gap: 10px;
            }

            .date {
              display: flex;
              color: var(--that_blue);
              gap: 10px;
              align-items: center;
            }

            .linkBtn {
              all: unset;
              cursor: pointer;
              margin-left: 5px;
            }
          }

          .rightSide {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            flex: 1;
            gap: 15px;

            > div {
              display: flex;
              flex-direction: row;
              gap: 10px;
              align-items: flex-end;

              > button {
                width: 150px;
              }
            }

            .link {
              display: flex;
              width: 200px;
              justify-content: center;
              align-items: center;
              margin-left: auto;
              gap: 10px;
              font-weight: 600;
              text-decoration: underline;
              color: var(--that_blue);

              > button {
                all: unset;
                cursor: pointer;
                //color: var(--that_blue);
              }

              > svg {
                color: var(--link_color);
              }
            }

            > button {
              margin-left: auto;
              width: 150px;
            }
          }
        }

        .bottom {
          margin-top: 25px;
        }
      }
    }

    //mobile version

    .wrapperMobile {
      display: flex;
      flex-direction: column;
      width: 100%;
      .pinnedIcon {
        position: absolute;
        right: 10px;
        top: 10px;
      }
      .usernameContainer {
        display: flex;
        gap: 5px;
      }
      > .id {
        display: none;
      }
      .joinEventCard {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .icon {
          display: flex;
          align-items: center;
        }
        svg {
          height: 30px;
          width: 96px;
        }
      }
      .top {
        display: flex;
        flex-direction: row;
        .userImage {
          position: relative;
          height: 120px;
          width: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
        }
        .image {
          object-fit: contain;
          height: 120px;
          border-radius: 4px;
          width: 120px;
          margin-right: 15px;
          align-self: baseline;

          &.tablet {
            width: 150px;
            height: 150px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          width: 100%;
          text-align: left;
          flex: 2;
          gap: 10px;
          .username {
            display: flex;
            align-items: flex-start;
            gap: 10px;
            svg {
              margin-top: 4px;
            }
            .hostData {
              display: flex;
              flex-direction: column;
              a {
                word-break: break-all;
              }
            }
          }

          .title {
            display: flex;
            flex-direction: row;
            gap: 5px;
            @media only screen and (max-width: 816px) {
              align-items: start;
              flex-direction: column;
            }
            > h1 {
              font-size: 18px;
            }
          }

          .date {
            display: flex;
            align-items: center;
            color: var(--that_blue);
            gap: 10px;
          }

          > svg {
            margin-left: auto;
          }
        }
      }

      .middle {
        margin: 10px 0;
        gap: 10px;
        display: flex;
        flex-direction: column;
        .description {
          font-size: 14px;
          color: #000;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .date {
          display: flex;
          align-items: center;
          color: #0016a2;
          gap: 10px;
        }

        .username {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;
        }

        > svg {
          margin-left: auto;
        }
      }

      .bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        > button {
          width: 150px;
          float: right;
        }

        > div {
          font-weight: 600;
          text-decoration: underline;
          color: var(--that_blue);

          svg {
            color: var(--link_color);
          }
        }
      }
    }
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.infiniteLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 8vh;

  > svg {
    animation: spin 2s linear infinite;

    width: 50px;
    height: 50px;

    > path {
      color: grey;
    }
  }
}

//
.eventTypesContainer {
  display: flex;
  align-items: center;
  width: 100%;
  @media only screen and(max-width:816px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
.eventTypesWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  @media only screen and(max-width:816px) {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .eventSelectWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    @media only screen and(max-width:768px) {
      flex-wrap: wrap;
    }
  }

  .eventSwitcher {
    display: flex;
    align-items: center;
    gap: 12px;

    .switcher {
      padding: 5px;
      background: #e6eff9;
      border: 1px solid #f2f2f2;
      border-radius: 8px;
      cursor: pointer;
      &:nth-child(2) {
        @media only screen and(max-width:1000px) {
          display: none;
        }
      }
    }
  }
}

.switcher__active {
  background: #173593 !important;
  border-radius: 8px;
}

.requestHostButton {
  border: 1px solid #173593;
  border-radius: 8px;
  background: #173593;
  color: #fff;
  padding: 9px 30px;
  cursor: pointer;
  white-space: nowrap;
  a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 70vh; // fix -it

  > svg {
    animation: spin 2s linear infinite;

    width: 50px;
    height: 50px;

    > path {
      color: grey;
    }
  }

  > span {
    color: grey;
    margin-top: 10px;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
