.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100000;

  > .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 900;

    > .modal {
      width: 600px;
      max-height: 600px;
      padding: 20px;

      background: white;
      border-radius: 16px;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
      @media screen and (max-width: 767px) {
        padding: 10px;
        width: 320px;
      }

      > .modalHeader {
        display: flex;

        height: 50px;
        background: var(--that_blue);
        margin: -20px -20px 0 -20px;

        overflow: hidden;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 767px) {
          margin: -10px -10px 0 -10px;
        }
        > span {
          color: white;
          margin: 20px;
        }
        > svg {
          margin-right: 22px;
          color: white;
          cursor: pointer;
        }
      }
      > .body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        > .title {
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
          -webkit-box-orient: vertical;

          margin-top: 10px;
        }
        > .dates {
          display: flex;
          gap: 20px;
          @media screen and (max-width: 767px) {
            gap: 10px;
          }
          > div > span {
            color: var(--that_grey);
            margin-right: 10px;
            @media screen and (max-width: 767px) {
              margin-right: 3px;
              font-size: 14px;
            }
          }
        }
        > .inputs {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: stretch;
          gap: 10px;
          /*  @media screen and (max-width: 767px) {
                        flex-direction: column;
                    } */
          > input {
            border-radius: 8px;
            border: 1px solid var(--that_grey);

            padding: 8px;

            width: 100px;
            font-size: 16px;
            @media screen and (max-width: 767px) {
              width: 55px;
            }
          }
          > div {
            width: auto;
          }
          > div > div > input {
            border-radius: 8px;
            border: 1px solid var(--that_grey);
            padding: 10px;

            width: 350px;
            font-size: 16px;
            @media screen and (max-width: 767px) {
              width: 130px;
              padding: 10px;
            }
          }
          > .saveBtn {
            width: 65px;
          }
        }
        > .progressHistory {
          display: flex;
          flex-direction: row;
          color: var(--that_green);
          gap: 0px;

          width: 100%;
          > .left {
            display: flex;
            flex: 1;
            flex-direction: column;
            gap: 10px;

            width: 50%;
            text-align: justify;
            h1 {
              @media screen and (max-width: 767px) {
                font-size: 14px;
              }
            }
          }
          > .right {
            flex: 1;
            text-align: left;
            overflow: scroll;
            height: 195px;
            > .line {
              display: flex;
              flex-direction: row;
              align-items: center;
              //justify-content: center;
              .qty {
                width: 40px;
              }
              &.top {
                > span {
                  color: var(--that_green);
                  margin-right: 80px;
                  @media screen and (max-width: 767px) {
                    margin-right: 50px;
                  }
                }
              }
            }
            > div {
              > span {
                color: var(--that_grey);
                margin-right: 40px;
                @media screen and (max-width: 767px) {
                  margin-right: 20px;
                }
              }
            }
            > span {
              font-weight: 600;
              margin-right: 80px;
              @media screen and (max-width: 767px) {
                margin-right: 20px;
              }
            }
          }
        }
        .modalActions {
          display: flex;
          margin-left: auto;
        }
      }
    }
  }
}

//circular progress bar
.circleWrap {
  width: 150px;
  height: 150px;
  background: #fefcff;
  border-radius: 50%;
  border: 1px solid var(--that_grey);
  @media screen and (max-width: 767px) {
    width: 120px;
    height: 120px;
  }
}

.circleWrap .circle .mask,
.circleWrap .circle .fill {
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 50%;
  @media screen and (max-width: 767px) {
    width: 120px;
    height: 120px;
  }
}

.circleWrap .circle .mask {
  clip: rect(0px, 150px, 150px, 75px);
  @media screen and (max-width: 767px) {
    clip: rect(0px, 120px, 120px, 60px);
  }
}

.circleWrap .insideCircle {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 122px;
  height: 122px;

  border-radius: 50%;
  background: var(--page_color);
  //line-height: 10px;
  text-align: center;
  justify-content: center;
  margin-top: 14px;
  margin-left: 14px;
  color: var(--that_green);
  position: absolute;
  font-weight: 700;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    width: 100px;
    height: 100px;
    //line-height: 95px;
    margin-top: 11px;
    margin-left: 11px;
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > p {
    //display: inline-block;

    margin: 0;
    padding: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 4ch;

    line-height: 0px;
  }
}

/* color animation */

/* 3rd progress bar */
.mask .fill {
  clip: rect(0px, 75px, 150px, 0px);
  background-color: var(--that_green);
  @media screen and (max-width: 767px) {
    clip: rect(0px, 60px, 120px, 0px);
  }
}

.mask.full,
.circle .fill {
  animation: fill ease-in-out 3s;
  //transform: rotate(135deg);
}

@keyframes fill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    //transform: rotate(135deg);
  }
}
