.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 100000;
  top: 0;
  right: 0;
  left: 0;

  width: 100%;
  height: 70px;
  // background-color: var(--clear_white);
  background-color: #031aab;
  box-sizing: border-box;
  // border-bottom: 1px solid var(--that_grey);

  > .line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    max-width: 2900px;

    margin: 0 21px;

    > a > .logo {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 240px;
      user-select: none;

      > span {
        color: var(--text_color);
        text-transform: uppercase;

        font-size: 24px;
        font-weight: 400;
        line-height: 34px;
        margin: auto;
      }
    }

    > .menu {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      > * {
        margin-left: 24px;
        user-select: none;
      }
    }

    > .rainbrow {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: var(--gradient);
      visibility: hidden;
    }

    @media screen and (min-width: 1920px) {
      margin: 0 143px;
    }
  }
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 44px;
  height: 44px;

  cursor: pointer;

  > a {
    > .line {
      width: 68px;

      display: flex;
      flex-direction: column;
      align-items: center;
      border: 2px solid #ffffff;
      border-radius: 8px;
      background: #173593;
      position: relative;
      padding: 10px 20px;
      margin: 5px 0;

      > svg {
        max-width: 20px;
        height: 20px;
        fill: var(--text_color);

        // color: var(--text_color);
        color: var(--clear_white);
      }

      > span {
        transition: 100ms;

        // color: var(--text_color);
        color: var(--clear_white);
      }

      opacity: 0.6;
      transition: 200ms;
    }

    > .line:hover {
      opacity: 1;
    }

    > .line.activeLine {
      opacity: 1;
      background-color: var(--clear_white);
      > svg {
        fill: var(--that_blue) !important;
      }
      > span {
        font-weight: 500;
        color: var(--that_blue) !important;
      }
    }
  }

  &:hover {
    opacity: 0.9;
  }

  transition: 300ms;
}

.inboxNotification {
  position: absolute;
  top: -5px;
  right: 30%;
  background: #e45959;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
