@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

.chatMessages {
  height: 100%;

  .chatUserInfo {
    font-family: 'Open Sans', sans-serif;
    border-bottom: 1px solid #b1b1b1;
    padding: 20px 50px 30px 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 768px) {
      padding: 30px 15px 15px;
    }

    @media only screen and (min-width: 819px) and (max-width: 950px) {
      padding: 30px 25px;
    }

    .chatUserName {
      display: flex;
      gap: 17px;
    }

    .closeBtn {
      width: 20px;
      cursor: pointer;
      display: none;

      @media (max-width: 768px) {
        display: block;
      }

      img {
        width: 100%;
        height: 20px;
      }
    }

    gap: 17px;

    div {
      h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        color: #363636;
        text-transform: none;

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .groupMembers {
        cursor: pointer;
      }

      span {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #b1b1b1;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 42px;
        height: 42px;
      }
    }
  }

  .messagesContainer {
    height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .messages {
      height: 100%;
      border-bottom: 1px solid #b1b1b1;
      margin-bottom: 20px;
      padding: 40px 0 50px 40px;
      max-height: calc(100vh - 300px);

      @media (max-width: 768px) {
        padding: 10px 15px;
        height: 82%; // fix it ?
      }

      @media only screen and (min-width: 819px) and (max-width: 950px) {
        padding: 10px 25px;
      }

      .messagesTitle {
        font-family: 'Open Sans';
        display: block;
        text-align: center;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #b1b1b1;
        margin-bottom: 30px;

        @media (max-width: 768px) {
          margin-bottom: 20px;
          font-size: 18px;
        }
      }

      .noMessages {
        display: block;
        text-align: center;
        font-size: 22px;
        margin-top: 20px;
        color: #b1b1b1;
      }

      .messageTime {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        white-space: nowrap;
        font-size: 14px;
        line-height: 19px;
        color: #b1b1b1;
        user-select: none;

        .messageTime__wrapper {
          display: flex;
          align-items: center;
          gap: 5px;

          svg {
            cursor: pointer;
          }
        }

        @media (max-width: 768px) {
          font-size: 12px;
        }
      }

      .messageParent {
        //
        display: flex;
        flex-direction: column;

        .messageText {
          min-height: 52px;
          border-radius: 8px;
          display: flex;
          //
          flex-direction: column;
          justify-content: center;
          //
          padding: 10px 20px;

          p {
            font-size: 16px;
            font-family: inherit;
            white-space: break-spaces;

            //
            margin: 0 !important;
            margin-block: 0 !important;
            margin-inline: 0 !important;

            word-break: keep-all;
          }

          @media (max-width: 768px) {
            font-size: 15px;
            min-height: 30px;
            padding: 6px 12px;
          }
        }

        .partUserName {
          span {
            color: #363636;
            font-family: Open Sans !important;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }

      .message {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 40px;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 9px;
          margin-bottom: 25px;
        }

        .messageAvatar {
          width: 40px;
          min-width: 40px;
          display: block;

          @media (max-width: 768px) {
            width: 30px;
          }

          img {
            width: 100%;
            border-radius: 50%;
            height: 40px;
          }
        }

        .messageParent {
          align-items: flex-start;

          .messageText {
            background: #e5e5e5;
            word-break: break-all;
          }

          .messageTime {
            padding: 10px;
          }
        }
      }

      .messageReverse {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 40px;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-end;
          gap: 9px;
          margin-bottom: 25px;
        }

        .messageAvatar {
          width: 40px;
          min-width: 40px;
          display: block;

          img {
            width: 100%;
            border-radius: 50%;
            height: 40px;
          }
        }

        .messageParent {
          align-items: flex-end;

          .messageText {
            background: #e6eff9;
            // justify-content: flex-end;
            word-break: break-all;
            width: fit-content;
          }

          .messageTime {
            padding: 10px;
          }
        }
      }
    }

    .formContainer {
      padding: 0 50px;

      @media (max-width: 768px) {
        padding: 0 15px;
      }

      @media only screen and (min-width: 819px) and (max-width: 950px) {
        padding: 0 25px;
      }
    }
  }
}

.welcomeContainer {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 992px) {
    width: 60%;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    display: none;
  }

  .welcomeIcon {
    color: #0a2647;
    font-size: 6em;
    width: 120px;
    height: 120px;

    @media (max-width: 992px) {
      font-size: 5em;
    }
  }

  .welcomeTitle {
    // font-size: 3rem;
    font-size: 2.3rem;
    letter-spacing: 0.1em;
  }
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 70vh; // fix -it

  > svg {
    animation: spin 2s linear infinite;

    width: 50px;
    height: 50px;

    > path {
      color: grey;
    }
  }

  > span {
    color: grey;
    margin-top: 10px;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.infiniteLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 8vh; // fix -it

  > svg {
    animation: spin 2s linear infinite;

    width: 50px;
    height: 50px;

    > path {
      color: grey;
    }
  }
}

.group__default__imagesBlock {
  display: flex;
  align-items: center;
  gap: 5px;
}

.groupSettings__block {
  position: relative;

  button {
    span {
      color: #173593 !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .groupSettings__items {
    border: 1px solid #173593;
    border-radius: 8px;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    width: 100%;
    z-index: 9;
    background-color: #fff;

    li {
      list-style: none !important;
      padding: 0 15px;

      span {
        color: #363636;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        cursor: pointer !important;
      }
    }
  }
}
