@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap');

:root {
  --that_red: #f31c1c;
  --light_red: #ffdede;
  --that_green: #009364;
  --light_green: #e7f5ef;
  --that_blue: #173593;
  --light_blue: #e6eff9;
  --that_yellow: #ffb800;
  --light_yellow: #fff1cd;
  --that_orange: #ff6b00;
  --light_orange: #ffe0c9;
  --that_pink: #bc0271;
  --light_pink: #f7e6ef;
  --that_purple: #7400bb;
  --light_purple: #f3e1ff;

  --that_grey: #b1b1b1;
  --light_grey: #e6e6e6;
  --that_black: #363636;

  --page_color: #f8f8f8;
  --text_color: var(--that_black);

  --gradient: linear-gradient(
    90deg,
    #65df46 0%,
    #0094ff 25%,
    #dc4eff 50%,
    #ff4a1a 75%,
    #ffb200 100%
  );

  // imaginenation
  --link_color: #173593;
  --clear_white: #ffffff;
  --gray_shadow: #00000080;

  // disposal
  --directory_text_background_blue: #c1d8f0;
  --directory_text_background_green: #caeadc;
  --directory_text_background_purple: #e2b3ff;

  --directory_text_blue: #4067df;
  --directory_text_green: #009364;
  --directory_text_purple: #641793;

  --goals_category_violet: #d4cdff;
  --goals_category_peach: #ffd8be;
  --goals_category_pink: #ffcde8;
  --goals_category_green: #c9fcd8;
  --goals_category_yellow: #ffebb8;
  --goals_category_purple: #ecceff;
  --goals_category_blue: #cde5ff;
  --goals_category_aqua_blue: #caffff;
}

html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;

  color: var(--text_color);
  background-color: var(--page_color);

  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;

  // scrollbar
  overflow: overlay;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--that_grey);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
}

//
//
//

h1 {
  font-size: 24px;
  line-height: 30px;
}

h2 {
  font-size: 20px;
  line-height: 28px;
}

h3,
h4 {
  font-size: 16px;
  line-height: 20px;
}

h1,
h2,
h3,
h4 {
  font-weight: 600;
  margin: 0;
}

span {
  font-size: 16px;
  font-weight: 400;
}

textarea {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}

a {
  color: var(--link_color);
  text-decoration: none;
}

img {
  pointer-events: none;
  user-select: none;
}

// @media (max-width: 1400px) {
//   body {
//     background-color: #909090;
//   }
// }

// @media (max-width: 1200px) {
//   body {
//     background-color: lightyellow;
//   }
// }

// @media (max-width: 992px) {
//   body {
//     background-color: lightcyan;
//   }
// }

// @media (max-width: 768px) {
//   body {
//     background-color: blue;
//   }
// }

// @media (max-width: 576px) {
//   body {
//     background-color: green;
//   }
// }

// @media only screen and (min-width: 819px) and (max-width: 950px) {
//   body {
//     display: none;
//   }
// }

// @media only screen and (min-width: 280px) and (max-width: 535px) {
//   body {
//     display: none;
//   }
// }
