.slider {
  text-align: center;
  height: 200px;
}
.windowContainer .slick-slider {
  position: inherit !important;
}
.slick-track {
  display: flex !important;
}

.badge-img-box {
  width: 100%;
  height: 450px;
  position: relative;
}
.badge-img-box img {
  width: 157px;
  height: 157px;
  position: relative;
  margin: auto;
  z-index: 1;
}
.slick-center .slider-box {
  transform: scale(1) !important;
  opacity: 1 !important;
  transition: all 0.4s ease;
  display: flex;
}
/* .activeSlider {
  transform: scale(1) !important;
  opacity: 1 !important;
  transition: all 0.4s ease;
  display: flex;
} */
.slider-box {
  transform: scale(0.7);
  opacity: 0.5;
  transition: all 0.4s ease;
}
.arrow {
  color: #fff;
  cursor: pointer;
  z-index: 9999;
  position: absolute;
  top: 155px;
}

.arrow.prev {
  left: 15px;
  display: block;
}
.arrow.next {
  right: 15px;
  display: block;
}
.arrow span {
  border: 1px solid rgb(138, 137, 137);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 99;
}

.slider-box-col {
  margin: 0 185px;
  position: relative;
  display: flex;
}
.badgesContent {
  display: flex;
  padding: 10px;
  position: relative;
  z-index: 9;
}
.badge-name {
  padding: 10px;
  /* position: absolute; */
  /* top: 25%;
  left: 50%; */
  /* transform: translate(-50%, 50%); */
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  z-index: 5;
}

.badge-name h2 {
  margin: 0;
  margin-bottom: 8px;
  color: #130f40;
}
.badge-name p {
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  width: 70%;
}

.hideText {
  display: none !important;
}
.showText {
  display: block !important;
}

.overlay:not(.windowContainer) {
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: 2em;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11000;
}

.windowContainer {
  width: 80%;
  margin: 0 auto;
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 40px;
  position: absolute;
  top: 2%;
  left: 1%;
  z-index: 99999;
  overflow: hidden;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
}
.whatIsIt {
  margin: 20px 0 10px 0;
}

.active {
  transform: scale(1.02);
  opacity: 1;
}
.notActive {
  transform: scale(0.9);
  opacity: 0.6;
}
.badgesImage {
  max-width: 500px;
  height: 140px;
}
.header {
  display: flex;
  justify-content: space-between;
}
h2 {
  text-transform: capitalize;
}

.closeModal {
  cursor: pointer;
}

@media screen and (max-width: 720px) {
  .windowContainer {
    width: 75%;
    left: 0 !important;
    right: 0;
    margin: 0 auto;
  }
}
