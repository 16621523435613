.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  border-radius: 8px;

  > svg {
    width: 24px;
    height: auto;
    max-height: 24px;
  }

  > span {
    font-weight: 600;
    font-size: 16px;
  }

  //
  //
  // colors

  &.red {
    background-color: var(--light_red);

    > span {
      color: var(--that_red);
    }

    > svg > path {
      fill: var(--that_red);
    }
  }

  &.orange {
    background-color: var(--light_orange);

    > span {
      color: var(--that_orange);
    }

    > svg > path {
      fill: var(--that_orange);
    }
  }

  &.yellow {
    background-color: var(--light_yellow);

    > span {
      color: var(--that_yellow);
    }

    > svg > path {
      fill: var(--that_yellow);
    }
  }

  &.green {
    background-color: var(--light_green);

    > span {
      color: var(--that_green);
    }

    > svg > path {
      fill: var(--that_green);
    }
  }

  &.blue {
    background-color: var(--light_blue);

    > span {
      color: var(--that_blue);
    }

    > svg > path {
      fill: var(--that_blue);
    }
  }

  &.pink {
    background-color: var(--light_pink);

    > span {
      color: var(--that_pink);
    }

    > svg > path {
      fill: var(--that_pink);
    }
  }

  &.purple {
    background-color: var(--light_purple);

    > span {
      color: var(--that_purple);
    }

    > svg > path {
      fill: var(--that_purple);
    }
  }

  &.grey {
    background-color: var(--clear_white);

    > span {
      color: var(--text_color);
    }

    > svg > path {
      fill: var(--text_color);
    }
  }
}
