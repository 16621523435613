@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap');

.editMessage__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background: rgba(51, 51, 51, 0.4);

  .modal {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    border-radius: 8px;
    width: 600px;

    background-color: #fff;
    padding: 30px 34px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 15px;

    h1 {
      font-family: inherit;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
    }

    .reactQuill_textArea {
      width: 100%;
      border: 1px solid #e6e6e6;
      border-radius: 8px;
      padding: 10px 13px;

      p {
        font-size: 15px;
        line-height: 19px;
      }
    }

    .modal__btns {
      display: flex;
      align-items: center;
      gap: 21px;
    }
  }
}
