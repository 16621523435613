.arrow span {
  width: 40px;
  height: 40px;
}

.arrow span img {
  width: 20px;
}

.badge-name h2 {
  margin-top: 42px;
}
.next {
  display: none;
}
.prev {
  display: none;
}

.test {
  margin-top: 30px;
}

.slider-box-col {
  margin: 0;
}

.ql-toolbar {
  display: none !important;
}

.ql-editor {
  max-width: 50vw;
}

.ql-container {
  border: none !important;
}

.rbc-btn-group:first-child {
  display: none !important;
}
.rbc-btn-group button {
  cursor: pointer;
}
.rbc-calendar {
  height: 150vh !important;
  width: calc(100vw - 100px);
  max-width: calc(100%);
}
