.spinner {
  width: 10px;
  height: 10px;
  border: 3px solid;
  border-color: var(--that_blue) var(--that_blue) transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
  margin: 22px 0 15px 0;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
