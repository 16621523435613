@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap');

.primary__modal__button {
  border: none;

  background: #173593;

  color: #fff;
}

.light__modal__button {
  border: 1px solid #173593;
  background-color: transparent;
  color: #173593;
}

.green__modal__button {
  border: 1px solid green;
  color: green;
  background-color: transparent;
}

.modal__button {
  cursor: pointer;
  border-radius: 8px;
  font-family: 'Open Sans', sans-serif;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 9px 15px;

  display: flex;

  align-items: flex-start;

  gap: 8px;

  span {
    font-family: 'Open Sans', sans-serif;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }
}
