.userImages1.partyUserImage1{
    height: 100px;
    width: 100px;
    border-radius: 100%;
}
.userImages2.partyUserImage1, .userImages2.partyUserImage2 {
    height: 100px;
    width: 100px;
    border-radius: 100%;
    position: absolute;
}
.userImages2.partyUserImage1{
   top: 10px;
    left: 10px;
}
.userImages2.partyUserImage2{
    bottom: 10px;
    right: 10px;
}

.userImages3.partyUserImage {
    height: 100px;
    width: 100px;
    border-radius: 100%;
}
.userImages4.partyUserImage {
    height: 100px;
    width: 100px;
    border-radius: 100%;
}
.noUserIcon{
    height: 100px;
    width: 100px;
}


@media only screen and (max-width:987px) {
    .userImages1.partyUserImage1{
        height: 70px;
        width: 70px;
    }
    .userImages2.partyUserImage1, .userImages2.partyUserImage2 {
        height: 60px;
        width: 60px;
    }
    .userImages2.partyUserImage1{
        top: 7px;
        left: 7px;
    }
    .userImages2.partyUserImage2{
        bottom: 7px;
        right: 7px;
    }

    .userImages3.partyUserImage {
        height: 48px;
        width: 48px;
        border-radius: 100%;
    }
    .userImages4.partyUserImage {
        height: 48px;
        width: 48px;
        border-radius: 100%;
    }
}