.wrapper {
  width: 100%;
  height: 100%;

  min-width: 180px;

  // border: 1px solid green;

  > .oneLine {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    margin: 30px 0 30px 0;
    @media screen and (max-height: 900px) {
      margin: 0 0 5px 0;
    }

    width: 100%;

    > label {
      display: flex;
      align-items: center;

      width: 100%;

      font-size: 16px;
      font-weight: 400;
      line-height: 22px;

      padding: 4px 1px;

      > .bubble {
        padding-left: 4px;
        width: 20px;
        height: 20px;
      }
    }

    > .field {
      display: flex;
      flex-direction: row;

      border: 1px solid var(--that_grey);
      border-radius: 8px;

      margin-bottom: 10px;

      > input {
        width: 100%;
        height: 40px;

        color: var(--text_color);
        box-sizing: border-box;
        padding: 8px 14px;

        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;

        border-radius: 8px;
        border: none;
        outline: none;
      }

      > .lupe {
        background: transparent;
        border: none;
        outline: none;

        margin: auto;
        margin-left: -32px;

        width: 22px;
        height: 22px;

        transition: 300ms;
        pointer-events: none;

        opacity: 0.7;
      }

      > input:not(:placeholder-shown) + .lupe {
        opacity: 0;
        margin-left: -26px;
      }

      > .select2 {
        // todo
        display: block;
      }
    }

    > .additionalOr {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0em;

      padding-left: 10px;
      height: 12px;
      line-height: 0px;
    }
  }

  > .inLine {
    display: flex;
    flex-direction: row-reverse;

    > * {
      width: calc(100% - 20px / 2);
    }

    > *:first-child {
      margin-left: 20px;
    }
  }
}

// regular
//
// collapsed

.collapsed {
  width: 38px;

  margin: auto;
  height: 38px;
  margin-top: 12px;

  .item {
    display: flex;
    width: 100%;
    height: 100%;

    > svg {
      margin: auto;
    }
  }
}

// collapsed
//
// anime

.wrapper > .item {
  transition: 300ms;

  span {
    transition: 100ms;
    animation: none;
  }
}
