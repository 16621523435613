.container {
  display: flex;
  flex-direction: column;
  border-bottom: 10px solid var(--gradient);
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  .beforeBanner {
    background-color: #031aab;
    position: relative;

    &:before {
      content: '';
      z-index: 1;
      transition: 300ms;
      pointer-events: none;
      position: absolute;
      padding-bottom: 10px;
      inset: 0;
      background: var(--gradient);
      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: xor;
    }
  }
  .first {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    gap: 30px;
    max-width: 1730px;
    color: #fff;
    padding: 35px 40px 85px 78px;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      padding: 50px 15px 105px 15px;
      text-align: center;
      gap: 10px;
    }
    .firstLeft {
      display: flex;
      flex-direction: column;
      // padding-left: 80px;
      > h1 {
        font-size: 48px;
        margin-bottom: 32px;
        @media only screen and (max-width: 768px) {
          font-size: 20px !important;
        }
      }
      > .center {
        height: 100%;
        max-width: 85%;
        align-items: center;
        button {
          display: flex;
          font-size: 24px;
          margin-top: 40px;
          padding: 34px;
          background: rgb(101, 223, 70);
          background: -moz-linear-gradient(
            90deg,
            rgba(101, 223, 70, 1) 0%,
            rgba(0, 148, 255, 1) 47%,
            rgba(220, 78, 255, 1) 100%
          );
          background: -webkit-linear-gradient(
            90deg,
            rgba(101, 223, 70, 1) 0%,
            rgba(0, 148, 255, 1) 47%,
            rgba(220, 78, 255, 1) 100%
          );
          background: linear-gradient(
            90deg,
            rgba(101, 223, 70, 1) 0%,
            rgba(0, 148, 255, 1) 47%,
            rgba(220, 78, 255, 1) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#65df46",endColorstr="#dc4eff",GradientType=1);
          color: var(--clear_white);
          // border: solid 3px var(--clear_white);
          justify-content: center;
          align-items: center;
          font-weight: bold;
          position: relative;

          @media only screen and (max-width: 1600px) {
            font-size: 18px;
            padding: 30px 20px;
          }

          @media only screen and (max-width: 768px) {
            padding: 16px !important;
            transform: translateY(305px);
            margin-top: 0;
            margin: 0 auto;
            font-size: 16px;
          }

          z-index: 999;
        }
        & p {
          text-align: left;
          font-size: 24px;
          @media only screen and (max-width: 768px) {
            display: none;
          }
        }
        &.box {
          min-width: 85%;
          gap: 10px;

          .borderDiv {
            width: 100px;
            border: 1px solid #3bacff;
            margin: 30px 0 40px 0;
            @media only screen and (max-width: 768px) {
              display: none;
            }
          }
          > img {
            margin: 10px 0px;
            width: 100%;
            @media only screen and (max-width: 1684px) {
              width: 80%;
            }
            @media only screen and (max-width: 768px) {
              display: 80% !important;
            }
          }
        }
      }
    }

    .firstRight {
      display: flex;
      justify-content: end;
      width: 100%;
      @media only screen and (max-width: 768px) {
        justify-content: center;
        width: 60%;

        img {
          min-width: auto !important;
          width: 320px !important;
        }
      }

      > img {
        max-height: 530px;
        max-width: 742px;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  > .second {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    margin-top: 116px;
    @media only screen and (max-width: 1600px) {
      margin-top: 70px;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 60px;
      padding: 0;
      gap: 14px;
    }
    > .topTxtDiv {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      > div {
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      > .colorDiv {
        display: flex;
        border-radius: 8px;
        margin-bottom: 25px;
        color: var(--directory_text_green);
        @media only screen and (max-width: 1600px) {
          h1 {
            margin-bottom: 15px;
          }
        }
        @media only screen and (max-width: 768px) {
          margin-bottom: -5px !important;
        }
      }
      > div > h1 {
        font-size: 40px;
        @media only screen and (max-width: 768px) {
          font-size: 20px !important;
        }
      }
    }

    .center {
      justify-content: center;
      max-width: 1000px;
      > p {
        text-align: center;
        font-size: 1.5rem;
        margin: 0px;
        @media only screen and (max-width: 768px) {
          max-width: 330px;
        }
      }
    }
    > button {
      display: flex;
      font-size: 24px;

      margin-top: 20px;
      padding: 25px;
      background-color: var(--clear_white);
      color: var(--that_blue);
      border: solid 1px var(--that_blue);
      width: 275px;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 768px) {
        margin-top: 6px !important;
      }
    }
  }
  .middle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    @media only screen and (max-width: 768px) {
      margin-top: 80px;
    }
    h3 {
      font-weight: 600;
      font-size: 40px;
      line-height: 49px;
      text-align: center;
      color: #000000;
      margin: 50px 0;
      @media only screen and (max-width: 768px) {
        font-size: 20px !important;
        max-width: 320px;
        margin: 0 auto !important;
        line-height: 26px;
      }
    }
    > .card {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 115px;
      align-items: center;
      margin: 30px;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin: 50px auto 0 auto !important;
        gap: 30px;
        text-align: center;
        max-width: 268px;
        margin: 0 auto;
      }
      > img {
        width: 100%;
        height: 100%;
        max-width: 681px;
        max-height: 475px;
        flex: 1;
      }
      .cardTxt {
        width: 100%;
        flex: 1;
        max-width: 718px;
        > .topTxt {
          border-radius: 8px;
          display: flex;

          flex-direction: column;

          > h1 {
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 40px;
            line-height: 51px;
            @media only screen and (max-width: 768px) {
              font-size: 20px !important;
              line-height: 26px !important;
              margin-bottom: 10px !important;
            }
          }
          &.blue {
            color: #0094ff;
          }
          &.purple {
            color: var(--directory_text_purple);
          }
          &.yellow {
            color: var(--that_orange);
          }
          &.green {
            color: var(--that_green);
          }
        }
        > p {
          font-size: 24px;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  .carouselSection {
    > h1 {
      margin-bottom: 30px;
      text-align: center;
      font-size: 40px;
      line-height: 51px;
    }
    > .slider {
      display: flex !important;
    }
  }

  .bottomList {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding: 50px 0 50px 0;
    margin-bottom: 30px;
    background-repeat: no-repeat;

    > h1 {
      font-size: 3rem;
      background: var(--gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding: 20px 0;
    }
    > p {
      font-size: 32px;
      font-weight: bold;
      @media only screen and (max-width: 768px) {
        font-size: 20px !important;
      }
    }
    > .iconsContainer {
      display: flex;
      flex-direction: row;
      align-self: center;
      align-items: center;
      justify-content: center;
      gap: 45px;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 12px;
        svg {
          height: 60px;
          width: 55px;
        }
      }
      > .iconsDivs {
        display: flex;
        gap: 45px;
        @media only screen and (max-width: 768px) {
          gap: 0;
        }
        > svg {
          cursor: pointer;
        }
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 70px 20px;

    background-color: #282828;
    color: #fff;
    font-size: 24px;
    position: relative;
    .mobileRights {
      display: none;
    }
    .footerLogo {
      width: 400px;
    }

    @media only screen and (max-width: 768px) {
      padding: 50px 20px;
      gap: 20px;
      .footerLogo {
        width: 250px;
      }
      .mobileRights {
        display: block;
      }
      img {
        max-width: 60% !important;
      }
    }
    &:before {
      content: '';
      z-index: 1;
      transition: 300ms;
      pointer-events: none;
      position: absolute;
      inset: 0;
      background: var(--gradient);
      top: 0;
      bottom: auto;
      padding: 3px;
      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: xor;
    }

    > img {
      max-width: 50%;
    }
    > p {
      margin: 0;
      text-align: center;
      font-size: 16px;
    }
    > .footerTop {
      display: flex;
      flex-direction: row;

      justify-content: center;
      align-items: center;
      gap: 100px;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
        gap: 20px;
        &:nth-child(2) {
          display: none;
        }
      }
      .about {
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
      > div {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    > .footerMiddle {
      display: flex;
      flex-direction: row;
      gap: 100px;

      > div {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: 1684px) {
  .container {
    p {
      font-size: 16px !important;
    }
  }
  .middle {
    h3 {
      margin: 40px 0 10px 0px !important;
    }
  }
  .card {
    gap: 55px;
    img {
      max-width: 421px !important;
    }
    h1 {
      font-size: 24px !important;
      line-height: 30px !important;
    }
  }
  .borderDiv {
    margin: 10px 0 20px 0 !important;
  }
  .firstLeft {
    h1 {
      margin-bottom: 5px !important;
      font-size: 24px !important;
    }
    img {
      width: 100% !important;
    }
  }
  .topTxt {
    h1 {
      margin-bottom: 20px !important;
      line-height: 28px !important;
    }
  }
  .cardTxt {
    max-width: 538px !important;
  }
  .container {
    button {
      font-size: 16px;
      padding: 27px;
    }
  }
  .colorDiv {
    margin-bottom: 15px !important;
  }
  .center {
    max-width: 720px !important;
  }
  .second {
    button {
      font-size: 16px !important;
      margin-top: 0 !important;
      padding: 19px !important;
    }
  }
  .topTxtDiv {
    margin-bottom: 0 !important;
  }
  .first {
    max-width: 1310px !important;
  }
  .firstRight {
    img {
      min-width: 300px;
      max-width: 500px !important;
    }
  }
}
.sliderTitle {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 60px;
  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
}
